<template>
    <svg :class="{ 'light-logo': darkMode }" xmlns="http://www.w3.org/2000/svg"
        width="68.661" height="68.661" viewBox="0 0 68.661 68.661">
        <g id="Group_18" data-name="Group 18" transform="translate(-864 -406)">
            <g id="Kraats" transform="translate(864.204 458.548)"
                style="isolation: isolate">
                <g id="Group_13" data-name="Group 13" style="isolation: isolate">
                    <path id="Path_1" data-name="Path 1"
                        d="M567.211,432.423V416.537H570.5v7.1l6.944-7.1h4.017l-7.716,7.557,8.079,8.329h-4.4l-6.922-7.376v7.376Z"
                        transform="translate(-567.211 -416.537)" fill="#273746" />
                    <path id="Path_2" data-name="Path 2"
                        d="M610.555,439.154V427.33h3.109v1.317a3.75,3.75,0,0,1,3.155-1.589,2.8,2.8,0,0,1,1.294.272v2.724a3.227,3.227,0,0,0-.749-.238,4.391,4.391,0,0,0-.84-.079,3.135,3.135,0,0,0-1.657.454,3.315,3.315,0,0,0-1.2,1.294v7.671Z"
                        transform="translate(-595.054 -423.268)" fill="#273746" />
                    <path id="Path_3" data-name="Path 3"
                        d="M638.177,439.439a5.166,5.166,0,0,1-2.246-.465,3.687,3.687,0,0,1-1.521-1.282,3.32,3.32,0,0,1-.544-1.884,3.091,3.091,0,0,1,1.282-2.644,5.855,5.855,0,0,1,3.529-.942,8.725,8.725,0,0,1,2.9.477v-.862a1.883,1.883,0,0,0-.635-1.544,2.875,2.875,0,0,0-1.861-.522,6.067,6.067,0,0,0-1.611.238,14.957,14.957,0,0,0-2,.738l-1.135-2.292a15.85,15.85,0,0,1,2.69-.953,10.709,10.709,0,0,1,2.576-.317,5.5,5.5,0,0,1,3.71,1.146,4.042,4.042,0,0,1,1.328,3.234v7.671h-3.064v-.84a4.955,4.955,0,0,1-1.566.794A6.43,6.43,0,0,1,638.177,439.439Zm-1.361-3.7a1.236,1.236,0,0,0,.59,1.078,2.761,2.761,0,0,0,1.566.4,5.051,5.051,0,0,0,1.43-.193,3.72,3.72,0,0,0,1.18-.578v-1.725a6.277,6.277,0,0,0-1.18-.341,7.261,7.261,0,0,0-1.317-.113,3.008,3.008,0,0,0-1.668.4A1.232,1.232,0,0,0,636.815,435.74Z"
                        transform="translate(-610.038 -423.349)" fill="#273746" />
                    <path id="Path_4" data-name="Path 4"
                        d="M673.141,439.439a5.166,5.166,0,0,1-2.247-.465,3.684,3.684,0,0,1-1.521-1.282,3.316,3.316,0,0,1-.545-1.884,3.09,3.09,0,0,1,1.282-2.644,5.854,5.854,0,0,1,3.529-.942,8.723,8.723,0,0,1,2.9.477v-.862a1.882,1.882,0,0,0-.636-1.544,2.872,2.872,0,0,0-1.86-.522,6.068,6.068,0,0,0-1.611.238,14.966,14.966,0,0,0-2,.738l-1.135-2.292a15.83,15.83,0,0,1,2.689-.953,10.706,10.706,0,0,1,2.576-.317,5.5,5.5,0,0,1,3.71,1.146,4.043,4.043,0,0,1,1.328,3.234v7.671h-3.064v-.84a4.958,4.958,0,0,1-1.566.794A6.433,6.433,0,0,1,673.141,439.439Zm-1.362-3.7a1.236,1.236,0,0,0,.59,1.078,2.76,2.76,0,0,0,1.566.4,5.051,5.051,0,0,0,1.43-.193,3.721,3.721,0,0,0,1.18-.578v-1.725a6.27,6.27,0,0,0-1.18-.341,7.262,7.262,0,0,0-1.316-.113,3.008,3.008,0,0,0-1.668.4A1.232,1.232,0,0,0,671.779,435.74Z"
                        transform="translate(-632.503 -423.349)" fill="#273746" />
                    <path id="Path_5" data-name="Path 5"
                        d="M708.515,433.208a4.369,4.369,0,0,1-2.985-.9,3.32,3.32,0,0,1-1.01-2.621v-5.9h-2.429V421.2h2.429v-3.018l3.109-.7V421.2h3.382v2.588H707.63v5.265a1.579,1.579,0,0,0,.363,1.169,1.808,1.808,0,0,0,1.271.352,5.548,5.548,0,0,0,.828-.057,4.614,4.614,0,0,0,.828-.215v2.564a6.343,6.343,0,0,1-1.192.25A9.95,9.95,0,0,1,708.515,433.208Z"
                        transform="translate(-653.863 -417.141)" fill="#273746" />
                    <path id="Path_6" data-name="Path 6"
                        d="M734.354,439.5a10.374,10.374,0,0,1-3.018-.42,7.347,7.347,0,0,1-2.405-1.215l1.543-2.065a9.234,9.234,0,0,0,1.963,1.021,5.478,5.478,0,0,0,1.872.34,3.392,3.392,0,0,0,1.668-.352,1.043,1.043,0,0,0,.624-.919.873.873,0,0,0-.352-.727,2.446,2.446,0,0,0-1.123-.385l-2.27-.34a4.826,4.826,0,0,1-2.677-1.146,3.05,3.05,0,0,1-.908-2.3,3.291,3.291,0,0,1,.6-1.986,3.93,3.93,0,0,1,1.7-1.294,6.713,6.713,0,0,1,2.621-.465,8.94,8.94,0,0,1,2.53.363,8.12,8.12,0,0,1,2.326,1.112l-1.5,2.019a7.9,7.9,0,0,0-1.861-.885,6.048,6.048,0,0,0-1.792-.273,2.53,2.53,0,0,0-1.35.318.956.956,0,0,0-.511.839.884.884,0,0,0,.363.749,2.749,2.749,0,0,0,1.248.386l2.247.34a4.842,4.842,0,0,1,2.7,1.123,2.959,2.959,0,0,1,.931,2.258,3.176,3.176,0,0,1-.681,2.008,4.657,4.657,0,0,1-1.839,1.385A6.525,6.525,0,0,1,734.354,439.5Z"
                        transform="translate(-671.117 -423.389)" fill="#273746" />
                </g>
            </g>
            <g id="Harm" transform="translate(864.204 406)"
                style="isolation: isolate">
                <g id="Group_14" data-name="Group 14" style="isolation: isolate">
                    <path id="Path_7" data-name="Path 7"
                        d="M566.124,322.153V302.736h4.022v7.822h8.931v-7.822H583.1v19.417h-4.022v-7.961h-8.931v7.961Z"
                        transform="translate(-566.124 -302.736)" fill="#273746" />
                    <path id="Path_8" data-name="Path 8"
                        d="M624.987,330.728a6.309,6.309,0,0,1-2.746-.569,4.5,4.5,0,0,1-1.859-1.567,4.051,4.051,0,0,1-.665-2.3,3.778,3.778,0,0,1,1.567-3.232,7.156,7.156,0,0,1,4.313-1.151,10.672,10.672,0,0,1,3.55.582v-1.054a2.3,2.3,0,0,0-.776-1.886,3.509,3.509,0,0,0-2.274-.638,7.415,7.415,0,0,0-1.969.291,18.276,18.276,0,0,0-2.441.9l-1.387-2.8a19.338,19.338,0,0,1,3.287-1.165,13.033,13.033,0,0,1,3.148-.388,6.722,6.722,0,0,1,4.535,1.4,4.939,4.939,0,0,1,1.622,3.952v9.376h-3.745v-1.026a6.037,6.037,0,0,1-1.914.97A7.854,7.854,0,0,1,624.987,330.728Zm-1.664-4.522a1.511,1.511,0,0,0,.721,1.318,3.374,3.374,0,0,0,1.914.485,6.185,6.185,0,0,0,1.748-.236,4.556,4.556,0,0,0,1.442-.707v-2.108a7.653,7.653,0,0,0-1.442-.416,8.832,8.832,0,0,0-1.609-.139,3.677,3.677,0,0,0-2.039.486A1.5,1.5,0,0,0,623.323,326.207Z"
                        transform="translate(-600.565 -311.062)" fill="#273746" />
                    <path id="Path_9" data-name="Path 9"
                        d="M664.3,330.38V315.929h3.8v1.608a4.584,4.584,0,0,1,3.855-1.941,3.41,3.41,0,0,1,1.581.333v3.328a3.947,3.947,0,0,0-.915-.291,5.356,5.356,0,0,0-1.026-.1,3.83,3.83,0,0,0-2.025.555A4.054,4.054,0,0,0,668.1,321v9.375Z"
                        transform="translate(-629.196 -310.964)" fill="#273746" />
                    <path id="Path_10" data-name="Path 10"
                        d="M695.714,330.479V316.027h3.8v1.109a5.848,5.848,0,0,1,6.3-.818,4.993,4.993,0,0,1,1.831,1.595,5.981,5.981,0,0,1,2.094-1.609,6.334,6.334,0,0,1,2.677-.555,5.509,5.509,0,0,1,2.871.735,5.249,5.249,0,0,1,1.942,2.025,6.165,6.165,0,0,1,.707,3.01v8.959h-3.772v-8.4a3.182,3.182,0,0,0-.749-2.233,2.578,2.578,0,0,0-2-.818,3.4,3.4,0,0,0-1.567.361,3.279,3.279,0,0,0-1.206,1.082q.027.25.056.5a5.1,5.1,0,0,1,.028.555v8.959h-3.8v-8.4a3.221,3.221,0,0,0-.735-2.233,2.534,2.534,0,0,0-1.983-.818,3.456,3.456,0,0,0-1.539.333,3.041,3.041,0,0,0-1.151,1v10.125Z"
                        transform="translate(-649.475 -311.062)" fill="#273746" />
                </g>
            </g>
            <g id="Group_17" data-name="Group 17"
                transform="translate(864 431.744)">
                <g id="van" transform="translate(0 5.267)"
                    style="isolation: isolate">
                    <g id="Group_15" data-name="Group 15"
                        style="isolation: isolate">
                        <path id="Path_11" data-name="Path 11"
                            d="M569.482,385.443l-4.925-11.37h1.861l3.881,9.192,3.881-9.192h1.793l-4.9,11.37Z"
                            transform="translate(-564.557 -373.846)"
                            fill="#273746" />
                        <path id="Path_12" data-name="Path 12"
                            d="M601.453,385.244a5.22,5.22,0,0,1-2.19-.431,3.532,3.532,0,0,1-1.475-1.2,3.075,3.075,0,0,1-.533-1.793,3.019,3.019,0,0,1,1.225-2.541,5.351,5.351,0,0,1,3.313-.93,7.185,7.185,0,0,1,3.336.771v-1.5a2.62,2.62,0,0,0-.772-2.054,3.264,3.264,0,0,0-2.247-.692,6.247,6.247,0,0,0-1.691.238,10.273,10.273,0,0,0-1.872.76l-.635-1.294a12.666,12.666,0,0,1,2.269-.862,8.225,8.225,0,0,1,2.088-.273,4.9,4.9,0,0,1,3.325,1.021,3.68,3.68,0,0,1,1.169,2.928v7.648h-1.634v-1.112a5.375,5.375,0,0,1-1.691.987A5.859,5.859,0,0,1,601.453,385.244Zm-2.587-3.473a1.856,1.856,0,0,0,.805,1.555,3.38,3.38,0,0,0,2.077.6,5.215,5.215,0,0,0,1.861-.318,4.293,4.293,0,0,0,1.521-1v-2.133a5,5,0,0,0-1.5-.692,6.851,6.851,0,0,0-1.793-.216,3.545,3.545,0,0,0-2.167.6A1.9,1.9,0,0,0,598.865,381.772Z"
                            transform="translate(-585.476 -373.443)"
                            fill="#273746" />
                        <path id="Path_13" data-name="Path 13"
                            d="M631.462,385.04V373.67h1.679v1.339a4.479,4.479,0,0,1,3.586-1.566,4.573,4.573,0,0,1,2.281.556,3.987,3.987,0,0,1,1.543,1.543,4.622,4.622,0,0,1,.556,2.3v7.194H639.45v-6.854a3.286,3.286,0,0,0-.851-2.394,3.1,3.1,0,0,0-2.326-.874,3.594,3.594,0,0,0-1.816.454,3.714,3.714,0,0,0-1.316,1.294v8.374Z"
                            transform="translate(-607.361 -373.443)"
                            fill="#273746" />
                    </g>
                </g>
                <g id="de" transform="translate(44.242)" style="isolation: isolate">
                    <g id="Group_16" data-name="Group 16"
                        style="isolation: isolate">
                        <path id="Path_14" data-name="Path 14"
                            d="M697.481,378.919a5.518,5.518,0,0,1-2.894-.783,5.748,5.748,0,0,1-2.065-2.111,6.214,6.214,0,0,1,0-5.957,5.784,5.784,0,0,1,2.065-2.1,5.562,5.562,0,0,1,2.917-.783,5.757,5.757,0,0,1,1.929.329,5.6,5.6,0,0,1,1.7.965v-5.628l1.679-.386v16.272h-1.657v-1.2A5.216,5.216,0,0,1,697.481,378.919Zm.158-1.475a4.719,4.719,0,0,0,1.986-.42,4,4,0,0,0,1.509-1.169v-5.628a4.108,4.108,0,0,0-1.509-1.146,4.728,4.728,0,0,0-1.986-.42,4.132,4.132,0,0,0-3.665,2.122,4.474,4.474,0,0,0-.556,2.235,4.539,4.539,0,0,0,.556,2.247,4.26,4.26,0,0,0,1.521,1.589A4.03,4.03,0,0,0,697.64,377.444Z"
                            transform="translate(-691.762 -362.466)"
                            fill="#273746" />
                        <path id="Path_15" data-name="Path 15"
                            d="M734.787,387.325a5.719,5.719,0,0,1-2.961-.783,5.84,5.84,0,0,1-2.894-5.118,5.918,5.918,0,0,1,.749-2.961,5.751,5.751,0,0,1,2.019-2.111,5.3,5.3,0,0,1,2.837-.783,4.936,4.936,0,0,1,2.735.783,5.643,5.643,0,0,1,1.929,2.111,6.141,6.141,0,0,1,.715,2.961v.522h-9.327a4.621,4.621,0,0,0,.715,2,4.191,4.191,0,0,0,3.551,1.906,5.567,5.567,0,0,0,1.725-.272,3.937,3.937,0,0,0,1.407-.772l1.044,1.112a7.319,7.319,0,0,1-1.985,1.055A6.977,6.977,0,0,1,734.787,387.325Zm-4.153-6.717h7.6a4.288,4.288,0,0,0-.669-1.827,4.022,4.022,0,0,0-1.327-1.271,3.4,3.4,0,0,0-1.748-.465,3.626,3.626,0,0,0-1.792.454,3.937,3.937,0,0,0-1.362,1.259A4.452,4.452,0,0,0,730.634,380.608Z"
                            transform="translate(-715.543 -370.849)"
                            fill="#273746" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    darkMode: {
      type: Boolean,
      default: false
    }
  }
}
</script>


<style scoped lang="scss">
svg g g path{
    transition: fill 0.5s;
}
svg.light-logo g g path {
  fill: #fff;
}
</style>
