<script setup>
    // img is a https://picsum.photos/200/300 placeholder
</script>

<template>
    <div class="project" :class="{ 'active': active, }">
        <div class="img">
            <img :src="img" alt="img">
        </div>
        <div class="details">
            <div class="inner">


                <h2>
                    {{ title }}
                </h2>
                <!-- close button (only show on active) -->
                <div class="tech">
                    <ul>
                        <li v-for="item in tech" :key="item">
                            {{ item }}
                        </li>
                    </ul>
                </div>
                <div class="description">
                    <p>{{ description }}</p>
                </div>
                <div class="links">
                    <a :href="Githublink" target="_blank"
                        rel="noopener noreferrer">Github repo</a>
                    <a :href="'https://' + LiveLink" target="_blank"
                        rel="noopener noreferrer">Live
                        demo</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Project',
    props: {
        title: String,
        img: String,
        LiveLink: String,
        Githublink: String,
        tech: Array,
        description: String,
        active: Boolean,
    },
    methods: {
        closeProject(event) {
            event.stopPropagation();
            this.$emit('close-click');
        },
    },

}
</script>


<style scoped lang="scss">
.project {
    overflow: hidden;
    position: relative;

    .img {
        height: 100%;
        transition: transform .3s;
        width: 100%;
        z-index: 2;

        img {
            flex-grow: 1;
            height: 100%;
            object-fit: cover;
            object-position: center;
            width: 100%;
        }
    }

    .details {
        align-items: flex-end;
        background-color: rgba(19, 19, 19, 0.3);
        backdrop-filter: blur(10px);
        color: #fff;
        height: 100%;
        left: 0;
        opacity: 0;

        transition: .3s;
        width: 100%;
        z-index: 3;

        .inner {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            padding: 8rem 4rem;

        }

        h2 {
            color: white;
            font-size: var(--step-6);
            text-align: right;

        }

        .tech ul {
            display: flex;
            gap: 1rem;
            margin-top: 1rem;
            flex-wrap: wrap;
            justify-content: flex-end;

            li {
                list-style-type: none;
                font-size: var(--step-0);
                font-weight: 600;
                padding: .5rem 1rem;
                border-radius: 50px;
                border: 2px solid white;
            }
        }

        .description {
            margin-top: 2rem;
            text-align: right;

            p {
                line-height: 1.6;
                font-size: var(--step--1);
            }

        }

        .links {
            display: flex;
            gap: 2rem;
            justify-content: flex-end;
            margin-top: 1rem;

            a {
                color: white;
                font-size: var(--step--1);
                border-radius: 50px;
                transition: .2s;

                &:hover {
                    color: var(--color-dark);
                }
            }
        }
    }
}

// 1350px
@media screen and (max-width: 1350px) {
    .project {
        .details {
            .inner {
                padding: 4rem 2rem;

            }

            .tech {
                ul {
                    li {
                        font-size: var(--step--1);
                    }
                }

            }
        }
    }
}


// From 1220px and up add the hover effect
@media (width >=1220px) {
    .project {

        .details {
            display: flex;
            position: absolute;
            top: 0;

            h2 {
                transform: translate(0, 100%);
                transition: .2s ease-in-out;
            }

            .tech ul {
                transform: translate(0, 350%);
                transition: .25s ease-in-out;
            }

            .description {
                transform: translate(0, 600%);
                transition: .3s ease-in-out;
            }

            .links {
                transform: translate(0, 850%);
                transition: .35s ease-in-out;
            }
        }

        &:hover,
        &.active {
            // cursor: pointer;

            .details {
                opacity: 1;

                h2,
                .tech ul,
                .links,
                .description {
                    transform: translate(0, 0);
                }
            }

            .img {
                transform: scale(1.1);
            }
        }
    }
}

@media (width < 1024px) {
    .project {
        display: flex;
        flex-direction: column;


        .details {
            display: grid;
            grid-template-rows: 0fr;
            z-index: 1;
            transition: .5s;


            .tech ul,
            .inner,
            .links {
                justify-content: flex-start;
            }

            .description,
            h2 {
                text-align: left;
            }

            .inner {
                overflow: hidden;
                padding: 0;
            }
        }

        &.active {
            .details {
                grid-template-rows: 1fr;
                opacity: 1;

                .inner{
                    padding: 4rem 2rem;
                }
            }
        }

    }
}
</style>
