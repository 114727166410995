<template>
    <span class="slider" data-scroll data-scroll-direction="horizontal"
        data-scroll-speed="30">

        <span v-for="(text, index) in textArray" :key="index">
            {{ text }}
        </span>
        <span v-for="(text, index) in textArray" :key="index">
            {{ text }}
        </span>
    </span>
</template>

<script>

export default {
    name: 'textSlider',
    data() {
        return {
            textArray: [
                'PHP, ',
                'BASH, ',
                'SCSS, ',
                'STRIPE, ',
                'MYSQL, ',
                'JAVASCRIPT, ',
                'VUEJS, ',
                'WORDPRESS, ',
                'STRAPI, ',
                'GITHUB, ',
            ],
        }
    },
}

</script>

<style scoped lang="scss">
.slider {
    top: 10rem;
    left: -100vw;
    position: absolute;
    font-size: calc(var(--step-7));
    white-space: nowrap;
    font-weight: 900;
    transform: translate(0, -50%);

    span {
        margin-right: .8rem;
    }
}


// hide on mobile
@media (max-width: 768px) {
    .slider {
        display: none;
    }
}
</style>
