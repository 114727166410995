<template>
    <div id="scroller" data-scroll-container>
        <div id="wrapper">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import locomotiveScroll from "locomotive-scroll";
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
    data() {
        return {
            delay: {
                wrapper: 2.2,
                scroller: 2100
            }
        }
    },
    props: {
        loadingBufferDone: Boolean
    },
    methods: {
        locoScroll() {
            let multiplierVal = .5;

            // // Scrollbar is duplicated when change page, so remove the old one.
            // let scrollbar = document.querySelector('.c-scrollbar');
            // if (scrollbar) {
            //     scrollbar.remove();
            // }

            if (navigator.userAgent.indexOf('Firefox') > -1) {
                multiplierVal = 1.1;
            } else if (navigator.userAgent.indexOf('Chrome') > -1) {
                multiplierVal = 0.70;
            } else if (navigator.userAgent.indexOf('Safari') > -1) {
                multiplierVal = 1.1
            }


            const scroll = new locomotiveScroll({
                el: document.querySelector('[data-scroll-container]'),
                smooth: true,
                multiplier: multiplierVal,
                mobile: {
                    smooth: true
                },
                tablet: {
                    smooth: true
                }
            });

            this.scrollToSection = (sectionId) => {
                scroll.scrollTo(`#${sectionId}`);
                console.log('scrollToSection');
            };



            if (window.innerWidth > 1024) {
                scroll.on("scroll", () => {
                    ScrollTrigger.update();

                });


                ScrollTrigger.addEventListener("refresh", () => scroll.update());
                ScrollTrigger.defaults({ scroller: "#scroller" });
                ScrollTrigger.scrollerProxy("#scroller", {
                    scrollTop(value) {
                        return arguments.length ? scroll.scrollTo(value, { duration: 0, disableLerp: true }) : scroll.scroll.instance.scroll.y;
                    },
                    getBoundingClientRect() {
                        return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
                    },
                    pinType: document.querySelector("#scroller").style.transform ? "transform" : "fixed"
                });
            }
        },

    },
    // beforeMount() {
    //     if (this.loadingBufferDone) {
    //         this.delay = {
    //             wrapper: .7,
    //             scroller: 600
    //         }
    //     }
    // },
    mounted() {
        this.locoScroll();
    }
}
</script>
