<script setup>
import Header from '@/components/Header.vue'
import Banner from '@/components/Banner.vue'
import textImg from '@/components/TextImg.vue'
import ProjectsDark from '@/components/ProjectsDark.vue'
import Footer from '@/components/Footer.vue'
import Contact from '@/components/Contact.vue'
import Experience from '@/components/Experience.vue'


</script>

<template>
  <Header />

  <Banner />

  <section id="about" class="intro">
    <textImg :title="'Wie ben ik?'" :img="'gezicht.webp'">
      <p>
        Tijdens mijn opleiding Software development vanuit het Technova
        College
        in Ede, ben ik opgeleid tot Web developer. Daar heb ik geleerd om met
        technieken zoals HTML, CSS, JavaScript, PHP en MySQL te werken. Ook
        ben
        ik erg geinteresseerd in tech en wil altijd vooruit blijven kijken en
        denken.
      </p>

      <p>
        Ik ben erg leergierig en vind het geweldig om iets te creëren vanuit het
        niets. Mijn doel is om dit proces goed doordacht en samenhangend te laten
        verlopen, zodat mijn projecten niet zomaar iets zijn, maar echt zorgvuldig
        en logisch in elkaar zitten
      </p>
    </textImg>

    <textImg :img="'keyboard.webp'" :swap="true">
      <p>
        In mijn vrije tijd ben ik vaak aan het kijken waar ik nog iets zou
        kunnen leren, of wat ik nog zou kunnen verbeteren.
      </p>

      <p>
        Ook vind ik het heerlijk om piano te spelen, of eens een keer lekker
        te
        gamen met vrienden.
      </p>
    </textImg>
  </section>

  <ProjectsDark />

  <Experience />

  <Contact />

  <Footer />
</template>

<script>
export default {
  name: 'HomeView',

  methods: {

  }

}
</script>

<style scoped lang="scss">
p {
  margin: 0;
  font-size: var(--step-0);
}

.intro {
  margin-top: -15rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

#home {
  margin-bottom: 20rem;
}
</style>
