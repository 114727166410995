<template>
    <div class="circle-wrapper" >
        <div class="circle" >
        </div>
    </div>
</template>
  
<script>


export default {
    name: 'BigCircle',
}
</script>

<style scoped lang="scss">
.circle {
    --circle-size: 2000px;
    aspect-ratio: 1/1;
    background: linear-gradient(90deg, var(--color-red), var(--color-blue));
    border-radius: 50%;
    left: calc(50% - var(--circle-size));
    overflow: hidden;
    position: absolute;
    top: calc(50% - (var(--circle-size) / 2));
    width: var(--circle-size);
    z-index: -1;
    animation: rotate 10s linear infinite;
}

// rotate animation
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

</style>
  