<template>
    <div class="mouse">
        <svg class="mouse-svg" xmlns="http://www.w3.org/2000/svg" width="33" height="51"
            viewBox="0 0 33 51">
            <g id="Group_48" data-name="Group 48">
                <g id="Rectangle_35" data-name="Rectangle 35"
                     fill="none" stroke="#273746"
                    stroke-width="3">
                    <rect width="33" height="51" rx="16.5" stroke="none" />
                    <rect x="1.5" y="1.5" width="30" height="48" rx="15"
                        fill="none" />
                </g>
                <g class="scroll" id="Ellipse_16" data-name="Ellipse 16"
                     fill="none" stroke="#273746"
                    stroke-width="3">
                    <circle cx="7" cy="7" r="7" stroke="none" />
                    <circle cx="7" cy="7" r="5.5" fill="none" />
                </g>
            </g>
        </svg>
    </div>
</template>

<script>

export default {
    name: 'Mouse'
}
</script>


<style scoped>
.mouse {
    position: absolute;
    bottom: 6rem;
}
.mouse-svg{
    position: relative;
}
.scroll{
    animation: bounce 2s infinite;
    transform: translate(9.5px, 10px);
}

/* animate Ellipse_16 to go up and down infinite */
/*  */
@keyframes bounce {
    10% {
        transform: translate(9.5px, 10px)
    }

    40% {
        transform: translate(9.5px, 27px)
    }
}




</style>
