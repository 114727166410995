<script setup>

import BigCircle from './BigCircle.vue';
import Slider from './Slider.vue';
import Table from './Table.vue';

</script>

<template>
    <section id="experience" class="experience">
        <BigCircle />
        <Slider />

        <div class="wrapper">
            <h2>
                Wat heb ik gedaan?
            </h2>

            <Table :title="Ervaringen.title" :items="Ervaringen.items" data-scroll
                data-scroll-speed="-.5" />
            <Table :title="Opleidingen.title" :items="Opleidingen.items" data-scroll
                data-scroll-speed="-1" />

        </div>

    </section>

</template>
  
<script>


export default {
    name: 'Experience',
    data() {
        return {
            Ervaringen: {
                items: [
                    { col1: 'Omnitief', col2: 'Web development', col3: 'Apr 2023 - heden' },
                    { col1: 'Omnitief', col2: 'Stage web development', col3: 'Sept 2022 - Feb 2023' },
                    { col1: "McDonalds Veenendaal", col2: 'Parttime aspirant medewerker', col3: 'Mrt 2019 - Jul 2022' },
                    { col1: 'Doelbewust Online Marketing', col2: 'Stage web development', col3: 'Sept 2021 - Feb 2022' },
                ],
                title: 'Ervaringen',
            },
            Opleidingen: {
                items: [
                    { col1: 'Technova College Ede', col2: 'Software development', col3: 'Sept 2020 - Sept 2023' },
                    { col1: 'Ichthus College Veenendaal', col2: 'Vmbo-tl, techniek', col3: 'Sept 2016 - Sept 2020' },
                ],
                title: 'Opleidingen',
            },


        }
    },
}
</script>

<style scoped lang="scss">
.experience {
    --spaceing: 20rem;
    padding-top: calc(var(--spaceing) + 10rem);
    position: relative;
    z-index: 3;

    h2 {
        margin-bottom: 5rem;
    }

    .table {
        margin-bottom: 2.5rem;
    }
}


@media screen and (max-width: 768px) {
    .experience {
        margin-top: calc(var(--spaceing) * -1);
        --spaceing: 5rem;
    }
}
</style>
  