<template>
    <section id="projects" class="projects bg--dark">
        <div class="wrapper">
            <div class="inner">
                <h2>Voor wie werk ik?</h2>
            </div>
        </div>
        <div class="projects-wrapper">
            <Project v-for="(project, index) in projects" :key="project.title"
                :title="project.title" :img="require('@/assets/' + project.img)"
                :LiveLink="project.LiveLink" :Githublink="project.Githublink"
                :tech="project.tech" :description="project.description"
                :ImageArray="project.ImageArray" :active="activeProject === index"
                @click="toggleProject(index)" @close-click="setProjectInactive" />
        </div>
    </section>
</template>
  
<script setup>
import Project from '@/components/Project.vue';

const projects = [
    {
        title: 'Clayco webshop',
        img: 'projectImg/clayco.webp',
        ImageArray: ['projectImg/clayco.webp', 'projectImg/clayco.webp', 'projectImg/clayco.webp'],
        LiveLink: 'clayco.harmvandekraats.nl',
        Githublink: '#',
        tech: ['Vue', 'SCSS', 'Strapi', 'Stripe', 'Custom PHP'],
        description: 'Clayco webshop was eigenlijk eerst een soort test projectje, maar het werd zo leuk dat ik het verder ging bouwen. Nu ben ik het verder aan het ontwikkelen met Stripe voor betalingen, ook dat je via Strapi alle content kan beheren. Er is ook een custom backend aan toegevoegd om de winkelmand sessies op te kunnen slaan, maar ook om Stripe betalingen goed te laten lopen.',
    },
    {
        title: 'Dough hover effect',
        img: 'projectImg/dough.webp',
        ImageArray: ['projectImg/dough.webp', 'projectImg/dough.webp', 'projectImg/dough.webp'],
        LiveLink: 'dough.harmvandekraats.nl',
        Githublink: '#',
        tech: ['HTML', 'CSS', 'Javascript', 'KUTE.js'],
        description: 'Dit is een hover effect gemaakt met KUTE.js. Het effect is gemaakt met een SVG en een mask. De SVG is gemaakt in Adobe XD. dit hover effectje is gemaakt voor een website van een klant. Deze was een bakker en vond het leuk om een soort deeg balletje te laten bewegen als je er met je muis overheen ging',
    },
    {
        title: 'Vps server',
        img: 'projectImg/server.webp',
        ImageArray: ['projectImg/server.webp', 'projectImg/server.webp', 'projectImg/server.webp'],
        LiveLink: 'add.friedmonkey.nl',
        Githublink: '#',
        tech: ['Ubuntu', 'Apache', 'PHP', 'MySQL', 'Certbot', 'Own bash scripts'],
        description: 'Deze server is gemaakt met Ubuntu. De server heeft een Apache webserver met een MySQL database. De server heeft een certificaat van Certbot. De server heeft een aantal bash scripts die ik zelf heb gemaakt. Ik Gebruik deze server om mijn eigen websites te hosten.',
    },
    {
        title: 'Network',
        img: 'projectImg/network.webp',
        ImageArray: ['projectImg/network.webp', 'projectImg/network.webp', 'projectImg/network.webp'],
        LiveLink: 'network.harmvandekraats.nl',
        Githublink: '#',
        tech: ['HTML', 'CSS', 'Javascript', 'PHP', 'MySQL'],
        description: 'Dit is een social media website. De website is gemaakt met HTML, CSS, Javascript, PHP en MySQL. De website heeft een eigen login systeem. De website heeft een eigen chat systeem. De website heeft een eigen post systeem.',
    },
];
</script>

<script>


export default {
    name: 'ProjectsDark',
    components: {
        Project,
    },
    data() {
        return {
            activeProject: -1,
        }
    },
    methods: {
        toggleProject(index) {

            if (this.activeProject != index) {
                this.activeProject = index;
            } else {
                this.activeProject = -1;
            }




        },
        setProjectInactive() {


            // add the class inactive to the project for the transition. after 500ms remove the class again
            const project = document.querySelector('.project.active');
            project.classList.add('inactive');
            this.activeProject = -1;


            setTimeout(() => {
                // document.body.classList.remove('no-scroll');
            }, 600);
        },
    },
}
</script>
  
<style scoped lang="scss">
.projects {
    --spaceing: 10rem;
    margin-top: calc(var(--spaceing) * -1);
    padding-bottom: calc(var(--spaceing) * 3);
    padding-top: var(--spaceing);
}

.bg--dark {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inner {
    display: flex;
    flex-direction: column;
    padding-top: 10rem;
}

.projects-wrapper {
    position: relative;
    width: min(100%, 2000px);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
    padding-top: 6vh;

    >.project {
        &:nth-child(even) {
            margin: -10vh 0 10vh 0;
        }
    }
}


@media screen and (max-width: 1220px) {


    .projects-wrapper {
        width: min(100%, 800px);
        grid-template-columns: repeat(1, 1fr);

        >.project {
            &:nth-child(even) {
                margin: auto;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .projects {
        --spaceing: 5rem;

    }
}
</style>
  