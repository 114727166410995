<script setup>
import Logo from '@/components/Logo.vue';

// big circle
import BigCircle from './BigCircle.vue';
</script>

<template>
  <header>
    <div class="wrapper">
      <logo ref="logo" :darkMode="isDarkArea" />
    </div>
  </header>
</template>
  
<script>


export default {
  name: 'Header',

  data() {
    return {
      isDarkArea: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.updateDarkArea);
      this.updateDarkArea();
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.updateDarkArea);
  },
  methods: {
    updateDarkArea() {
      // Check if the logo is on top of a dark background element
      const logoElement = this.$refs.logo.$el; // Assuming you have a ref named 'logo' in Logo component
      const backgroundElements = document.querySelectorAll('.bg--dark');

      const logoRect = logoElement.getBoundingClientRect();
      const logoCenterY = logoRect.top + logoRect.height / 2;

      let isLogoOverDarkArea = false;

      backgroundElements.forEach(backgroundElement => {
        const backgroundRect = backgroundElement.getBoundingClientRect();

        if (logoCenterY > backgroundRect.top && logoCenterY < backgroundRect.bottom) {
          isLogoOverDarkArea = true;
          return; // Exit the loop early if we find a dark area
        }
      });

      this.isDarkArea = isLogoOverDarkArea;
    },
  },
};
</script>

<style scoped lang="scss">
header {
  padding: 1rem 0;
  z-index: 10;

  display: flex;
  justify-content: center;

  position: absolute;
  top: 0;

  .wrapper {
    width: var(--container-width);
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
    .content,
    .bigCircle {
      display: none;
    }
  }
}
</style>
  