<template>
  <div class="table">
    <div class="table__header">
      <h3>{{ title }}</h3>
    </div>
    <div class="table__body">
      <!-- Loop through object, and for each thing inside the object, make the rows and columns -->
      <div v-for="(value, key) in items" :key="key" class="table__row">
        <div class="table__cell" v-for="(item, index) in value" :key="index">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { ref } from 'vue';
  // If you need any additional setup logic, you can do it here
</script>
  
<script>
export default {
  name: 'Table',
  props: {
    title: String,
    items: Object,
  },
};
</script>
  
<style scoped lang="scss">
/* Add your table styles here */
.table {}

.table__header {
  h3 {
    font-size: var(--step-4);
    font-weight: 100;

  }

  border-bottom: 3px solid var(--color-dark);
  padding-bottom: 1rem;
}

.table__body {
  font-size: var(--step-0);
  padding-block: 1rem;

}

.table__row {
  display: flex;
  flex-direction: row;

  // &:hover{
  //   background-color: var(--color-red-darker);
  //   border-radius: 5px;
  // }

}

.table__cell {
  display: flex;
  flex-direction: column;
  width: calc(100% / 3);
}


@media screen and (max-width: 980px) {
  .table__row {
    flex-direction: column;
  }

  .table__cell {
    width: 100%;

    // make first item in row bold
    &:first-child {
      font-weight: bold;
    }

    // add margin to last item in row
    &:last-child {
      margin-bottom: 1rem;
    }
  }
}
</style>
  