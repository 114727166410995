<script setup>
import BigCircle from '@/components/BigCircle.vue'

import mouse from './mouse.vue';
</script>

<template>
    <section id="home" class="banner">
        <BigCircle/>

        <div class="wrapper">
            <div class="banner_container">
                <div class="banner_content">
                    <h1 data-scroll data-scroll-speed="1">Harm van de Kraats
                    </h1>
                    <p data-scroll data-scroll-speed=".8">Webdeveloper in the
                        Netherlands</p>
                </div>
            </div>
        </div>
        <mouse />
    </section>
</template>
  
<script>

export default {
    name: 'Banner',
}
</script>

<style scoped lang="scss">
.banner {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    // width: 100vw;
    // margin-bottom: calc(var( --space-2xl) * 4);
    .banner_container {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        // width: 100vw;
        height: 94vh;
        width: fit-content;
        overflow: hidden;

        &::before {
            content: "";
            border-top: 3px solid #273746;
            background-repeat: no-repeat;
            background-size: 100%;
            height: 5px;
            width: 191px;
            display: block;
            margin-right: 100px;
        }


        h1 {
            font-size: var(--step-7);
            font-weight: 900;
            line-height: 1;
            margin: 0.2em 0;
        }

        p {
            font-size: var(--step-0);
            margin-top: -1rem;
        }
    }
}

// media qury 900px
@media screen and (max-width: 900px) {

    .banner {
        .banner_container {
            flex-direction: column;
            align-items: flex-start;

            &::before {
                margin: 0;
            }

            .banner_content {
                display: flex;
                flex-direction: column;
                width: 80vw;
            }
        }

    }
}
</style>
  