<script setup>
import WrapperVue from './components/Wrapper.vue';
</script>

<template>
  <WrapperVue>
    <router-view /> 
  </WrapperVue>
</template>

<script>
export default {
  name: 'App',

  methods: {
    scrollToSection(sectionId) {
      // Call the scrollToSection method in WrapperVue component
      // this.$refs.wrapperVue.scrollToSection(sectionId);
      console.log(sectionId);
    },
  }
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Questrial&family=Red+Hat+Display:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* @link https://utopia.fyi/type/calculator?c=320,18,1.2,1920,22,1.25,7,4,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=3xs,3xl,3xl,12 */

:root {
  --color-white: #FEFCF7;
  --color-dark: #020511;

  --color-blue: #F0F3FB;
  --color-blue-darker: #9baacc;
  --color-red: #FFEAEA;
  --color-red-darker: #ff9b9b;
  --color-green: #94ffaa;

  --background-color: var(--color-dark);
  --text-color: var(--color-white);

  --container-width: clamp(2em, 90vw, 85em);

  //containerwidth with an min max 100vw
  --container-width-clamp: clamp(2em, 90vw, 80em);


  --gap: 1em;
  --container-clamp: clamp(2em, 13vw, 5em);

  --font-family: 'Red Hat Display', sans-serif;


  // Type scale
  --step--4: clamp(0.48rem, calc(0.48rem + 0.03vw), 0.51rem);
  --step--3: clamp(0.58rem, calc(0.57rem + 0.06vw), 0.64rem);
  --step--2: clamp(0.69rem, calc(0.67rem + 0.11vw), 0.80rem);
  --step--1: clamp(0.83rem, calc(0.80rem + 0.17vw), 1.00rem);
  --step-0: clamp(1.00rem, calc(0.95rem + 0.25vw), 1.25rem);
  --step-1: clamp(1.20rem, calc(1.13rem + 0.36vw), 1.56rem);
  --step-2: clamp(1.44rem, calc(1.34rem + 0.51vw), 1.95rem);
  --step-3: clamp(1.73rem, calc(1.59rem + 0.71vw), 2.44rem);
  --step-4: clamp(2.07rem, calc(1.88rem + 0.98vw), 3.05rem);
  --step-5: clamp(2.49rem, calc(2.22rem + 1.33vw), 3.82rem);
  --step-6: clamp(2.99rem, calc(2.63rem + 1.78vw), 4.77rem);
  --step-7: clamp(3.58rem, calc(3.11rem + 2.38vw), 5.96rem);

  // spaceing
  --space-3xs: clamp(0.31rem, calc(0.30rem + 0.06vw), 0.38rem);
  --space-2xs: clamp(0.56rem, calc(0.54rem + 0.13vw), 0.69rem);
  --space-xs: clamp(0.88rem, calc(0.84rem + 0.19vw), 1.06rem);
  --space-s: clamp(1.13rem, calc(1.08rem + 0.25vw), 1.38rem);
  --space-m: clamp(1.69rem, calc(1.61rem + 0.38vw), 2.06rem);
  --space-l: clamp(2.25rem, calc(2.15rem + 0.50vw), 2.75rem);
  --space-xl: clamp(3.38rem, calc(3.23rem + 0.75vw), 4.13rem);
  --space-2xl: clamp(4.50rem, calc(4.30rem + 1.00vw), 5.50rem);
  --space-3xl: clamp(6.75rem, calc(6.45rem + 1.50vw), 8.25rem);

  // grid
  --grid-max-width: 206.25rem;
  --grid-gutter: var(--space-3xs-3xl, clamp(0.31rem, calc(-0.54rem + 4.26vw), 8.25rem));
  --grid-columns: 12;
}

.u-container {
  max-width: var(--grid-max-width);
  padding-inline: var(--grid-gutter);
  margin-inline: auto;
}

.u-grid {
  display: grid;
  gap: var(--grid-gutter);
}

.u-grid--center {
  justify-content: center;
}

.bg {
  &--dark {
    --background-color: var(--color-dark);
    --text-color: var(--color-white);
    background-color: var(--background-color);
    color: var(--text-color);
  }
}

*,
*::before,
*::after,
body,
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

h1,
h2 {
  text-wrap: balance;
}

h2 {
  font-size: var(--step-5);
}

body {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
}

#wrapper {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  position: relative;
}

.wrapper {
  width: var(--container-width);
  // padding-inline: 0.5rem;
}


#center {
  display: flex;
  justify-content: center;
  position: relative;
}

nav {
  a {
    font-weight: bold;
    text-decoration: none;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}


// media qury 1920px
@media screen and (max-width: 1920px) {
  :root {
    --container-width: clamp(2em, 90vw, 75em);
  }
}
</style>
