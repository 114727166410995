<template>
    <section id="contact" class="contact bg--dark">
        <div class="wrapper">
            <h2>Contact</h2>

            <form @submit.prevent="submitForm"  :class="{ 'hide': succes }">
                <div class="form-input">
                    <div class="information">
                        <div class="info">
                            <label for="name">Naam</label>
                            <input type="text" v-model="name" placeholder="John Doe"
                                name="name" required>
                        </div>
                        <div class="info">
                            <label for="email">Email</label>
                            <input type="email" v-model="email"
                                placeholder="johndoe@email.nl" name="email"
                                required>
                        </div>
                    </div>
                    <div class="message">
                        <div class="info">
                            <label for="message">Bericht</label>
                            <textarea v-model="message" name="message"
                                placeholder="Ha Harm!"
                                required></textarea>
                        </div>
                    </div>
                </div>
                <input type="submit" value="Send" class="btn">
            </form>

            <div v-if="succes" class="succes">
                <p>Bedankt voor je bericht! Ik zal zo snel mogelijk contact met je opnemen.</p>
            </div>
            <!-- else if false -->
            <div v-else-if="succes === false" class="succes">
                <p>Er is iets mis gegaan. Probeer het later nog eens.</p>
            </div>

            <div class="img">
                <img :src="require('@/assets/zeikant.webp')" alt="img">
            </div>
        </div>
    </section>
</template>
  
<script>
import axios from 'axios';

export default {
    name: 'Contact',

    data() {
        return {
            name: '',
            email: '',
            message: '',
            succes: null,
        };
    },

    methods: {
        async submitForm() {
            const formData = new FormData();

            formData.append('name', this.name);
            formData.append('email', this.email);
            formData.append('message', this.message);
            try {
                // send form data to api with axios post request with response
                const response = await axios.post('https://api.harmvandekraats.nl', formData);
                // const response = await axios.post('http://localhost/1-own-projects/portfoliosite/server/', formData);

                console.log(response);
                // Clear form fields after successful submission
                this.name = '';
                this.email = '';
                this.message = '';

                this.succes = true;


            } catch (error) {
                console.log(error);
                this.succes = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.contact {
    margin-top: 10em;
    padding-block: 10em;
    width: 100%;
    z-index: 4;
    

    h2 {
        margin-bottom: 1em;
    }

    .hide{
        height: 0;
        overflow: hidden;
    }

    .wrapper {
        margin: 0 auto;
    }


    form {
        display: flex;
        flex-direction: column;
        height: 400px;
        transition: .3s;

        .information {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 2rem;

            .info {
                width: 100%;

                input {
                    width: 80%;
                }
            }
        }

        .info {
            font-size: var(--step-0);
            border-bottom: 1px solid var(--color-white);
            transition: .2s;

            label {
                margin-right: 2rem;
                transition: .2s;
            }
        }

        input,
        textarea {
            border: none;
            background-color: transparent;
            color: var(--color-white);
            font-size: var(--step-0);
            margin: 1rem 0;
            transition: .2s;
            font-family: var(--font-family);

            &:focus {
                outline: none;
                // border-bottom: 1px solid var(--color-dark);
            }
        }



        // input and textarea only a bottom border

        .info:has(:focus) {
            label {
                color: var(--color-green);
            }

            border-bottom: 1px solid var(--color-green);
        }


        input {
            padding: 1rem .5rem 0 1rem;

            &.btn {
                margin-top: 3em;
                width: 20%;
                background-color: var(--color-white);
                color: var(--color-dark);
                border-radius: 30px;
                padding: 1rem 0;
                font-size: var(--step-0);
                transition: .2s;
                font-family: var(--font-family);
                border-bottom: 2px solid transparent;


                &:hover {
                    background-color: var(--color-dark);
                    color: var(--color-white);
                    cursor: pointer;
                    border-radius: 0;
                    animation: buttonHover .5s;
                    // forwards
                    animation-fill-mode: forwards;
                }
            }
        }

        @keyframes buttonHover {
            0% {
                border-radius: 30px;
            }

            50% {
                border-radius: 30px;
                border-bottom: 2px solid transparent;
            }

            100% {
                border-bottom: 2px solid var(--color-white);
                border-radius: 0;
            }
        }

        textarea {
            width: 100%;
            min-height: 6rem;
            resize: vertical;

            // scrollbar 
            &::-webkit-scrollbar {
                width: .8rem;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background: var(--color-white);
                border-radius: 30px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: var(--color-white);
                cursor: pointer;
            }

        }
    }

    .img {
        margin-top: 3em;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

}

textarea {
    transition: none;
}

// media qury 900px
@media screen and (max-width: 950px) {
    .contact form input.btn {
        width: 100%;
    }

    .contact .information .info {
        display: flex;
        flex-direction: column;

        input {
            padding-inline: 0;
        }
    }

    // display: flex;
}
</style>
  