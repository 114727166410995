<template>
    <footer>
        <ul>
            <li><a href="https://github.com/HarmKraats" target="_blank">Github</a></li>
            <li><a href="https://www.instagram.com/harmkraats/" target="_blank">Instagram</a></li>
            <li><a href="https://www.linkedin.com/in/harm-van-de-kraats-536261133/" target="_blank">Linkedin</a></li>
            <li><a
                    href="mailto:harmkraats@gmail.com">harmkraats@gmail.com</a>
            </li>
        </ul>
        <div class="line"></div>
    </footer>
</template>
  
<script>


export default {
    name: 'Footer',

}
</script>

<style scoped lang="scss">
footer {
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    height: 12rem;
    padding: 2rem;
    background-color: var(--color-white);
    display: flex;

    ul {
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        width: min-content;
        position: relative;

        li {
            list-style-type: none;
            margin: 0 3rem;

            a {
                color: var(--color-black);
                text-decoration: none;
                font-size: var(--step-0);
                transition: .2s;
                position: relative;


                &:hover {
                    color: var(--color-dark);

                    &::after {
                        opacity: 1;
                        bottom: -1rem;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    transform: translate(-50%, 50%);
                    left: 50%;
                    bottom: -2rem;
                    opacity: 0;
                    background-color: var(--color-dark);
                    transition: .2s;
                }
            }
        }

    }

    .line {
        border-top: 3px solid #273746;
        background-repeat: no-repeat;
        background-size: 100%;
        height: 5px;
        width: 191px;
        margin-bottom: 2rem;
    }
}


// media qury 900px
@media screen and (max-width: 900px) {
    .line {
        display: none;
    }

    footer {
        height: auto;
        align-items: flex-start;

        ul {
            flex-direction: column;
            align-items: flex-start;

            li {
                margin: 0;
                margin-block: 1rem;

                a {

                    &::after {
                        left: -2rem;
                        bottom: 50%;
                        // opacity: 0;
                    }

                    &:hover{
                        &::after {
                            opacity: 1;
                            left: -1rem;
                            bottom: 50%;
                        }
                    }
                }
            }
        }
    }
}
</style>
  