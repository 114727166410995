<template>
    <section class="TextImage">
        <div class="container">
            <h2 v-if="this.title" data-scroll data-scroll-speed="-1">{{ this.title
            }}</h2>
            <section class="textImgWrapper" :class="swap && 'swap-direction'">
                <div class="text" data-scroll data-scroll-speed="-.3"
                    data-stagger="0.02">
                    <slot></slot>
                </div>
                <div class="img" @click="msg" data-scroll data-scroll-speed=".6">
                    <img :src="require('@/assets/' + this.img)" alt="img">
                </div>
            </section>
        </div>
    </section>
</template>
  
<script>

export default {
    name: 'TextImg',
    props: {
        title: String,
        img: String,
        swap: Boolean
    },
    methods: {
        msg() {
            console.log('clicked');
        }
    }
}
</script>

<style scoped lang="scss">
.TextImage {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10rem;
    width: var(--container-width);
    margin: 0 auto;
}

.container {
    // width: var(--container-width);

    h2 {
        text-align: right;
        padding-right: 5em;
        margin-bottom: 2em;
    }

    .textImgWrapper {
        display: flex;
        gap: 5rem;

        &.swap-direction {
            flex-direction: row-reverse;
            justify-content: flex-end;
            // margin-bottom: -2rem;
        }

        .text {
            --line-gap: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            height: fit-content;
            max-width: 40rem;
            position: relative;
            margin-left: var(--line-gap);

            &:before {
                content: "";
                position: absolute;
                width: 3px;
                height: 100%;
                background-color: #273746;
                left: calc(var(--line-gap) * -1);
            }
        }

        .img {
            align-items: center;
            height: 350px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            overflow: hidden;
            width: 350px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 2;
            }
        }
    }
}

@media screen and (max-width: 1920px) {
    .TextImage {
        width: calc(var(--container-width) * 1.2);
        max-width: 90vw;

    }
}

@media screen and (max-width: 1220px) {
    .TextImage {
        align-items: center;
        margin-bottom: 5rem;
    }

    .container {
        .textImgWrapper {
            gap: 2rem;

            &.swap-direction {
                flex-direction: column;
                align-items: center;
            }

            flex-direction: column;
            align-items: center;
        }
    }
}

@media screen and (max-width: 768px) {
    // move the one that is in reverse a little more dowjn
    .TextImage {
        .container {
            .textImgWrapper {
                &.swap-direction {
                    margin-bottom: -5rem;
                }
            }
        }
    }
}

@media screen and (max-width: 560px) {
    .container {
        h2 {
            text-align: left;
            padding-right: 0;
        }
    }
}

// .textImg {
//     display: flex;
//     flex-direction: column;

//     h2 {
//         margin: 0 17rem 7rem 0;
//         text-align: right;
//     }

// }

// section {
//     align-items: center;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     gap: 3rem;
// }

// .swap-direction {
//     align-items: flex-start;
//     flex-direction: row-reverse;
//     margin-bottom: 5rem;
// }
</style>
  